import React from "react"
import { jobManagerPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const JobManager = () => {
  return (
    <Layout>
      <Seo
        title="Streamline Accounting Practice Management with Synkli Job Manager"
        description={`Effortlessly manage your accounting practice with Synkli's Job Manager. Automate tasks, share data, and boost efficiency by 30%. Join Synkli today!`}
      />

      <FeatureDetailsPage data={jobManagerPage} />
      
    </Layout>
  )
}

export default JobManager
